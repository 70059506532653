

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "~@ng-select/ng-select/themes/default.theme.css"; 
@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic');




body {
    font-size: .875rem;
    overflow: hidden;
    height:100%;
  }


.small-grey-text {
  font-size: 12px;
  color: rgb(150, 150, 150);
}

// For Campaign sidebar
.ng-select.ownerSelection .ng-select-container {
  background: transparent !important;
}





// Scrool bar
::-webkit-scrollbar-track{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #69418B;
}

::-webkit-scrollbar
{
	width: 5px;
	height: 5px;
	background-color: #bebebe;
}

::-webkit-scrollbar-thumb
{
	background-color: #e5cffa;

}


::-webkit-scrollbar-thumb:hover {
    background-color: #a07bbd;
}


// Global items

table tr td {
	font-size: 12px;
	color: rgb(131, 131, 131);
}



.map-text-header {
  color: #43116E;
  font-size: 18px !important;

}



.btn-primary {
  background: #543074;
  &:hover,
  &:focus,
  &:active {
      background-color: lighten( #543074, 5%)!important;
  }
  &.active {
      background-color: darken( #543074, 20%)!important;
  }
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #696969;
}

.btn-warning {
  background: #EF6C00;
  &:hover,
  &:focus,
  &:active {
      background-color: lighten( #EF6C00, 5%)!important;
  }
  &.active {
      background-color: darken( #EF6C00, 20%)!important;
  }
}

.btn-link {
  color: #543074;
}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #543074;
  border-color: #543074;
}


a {
  color: #543074;
  text-decoration: none;
}

.cursor-pointer{
  cursor: pointer;
}


.text-primary {
  color: #543074 !important; 
}

.bg-primary {
  background-color: #543074 !important; 
}

.btn-outline-primary {
  border-color: #543074 !important;
  color: #543074 !important;
}

.nav-tabs {
  border-bottom: 0;
  background-color: rgb(232, 236, 240) !important;
}

.nav-link {
  color: #543074;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #543074;
  border-color: #ef6c00;
  background-color: #f5f5f5;
}

.text-secondary {
  color: #ef6c00 !important;
}


 
.map-color1 {
  color: #543074;
}

.map-color2 {
  color: #EF6C00;
}
 

.text-fix {
  font-size: 13px;
  color: rgb(135, 134, 134);
}


.ng-select.map-ng-select {
  border:0px !important;
  min-height: 0px !important;
  border-radius: 0 !important;
  font-size: 10px;
}
.ng-select.map-ng-select .ng-select-container  {            
  min-height: 0px !important;
  border-radius: 0 !important;
  height: 28px;
  width: 250px;
}



//  :host .tox .tox-tbtn{ color:red!important}